import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import Head from 'next/head';

import { DescriptionText } from 'src/components/utils/Typography';
import { PageWithFullImageBg } from 'src/containers/PageWithFullImageBg';
import { globalContext } from 'src/context';
import { AnalyticsService } from 'src/services/Analytics';
import { FullPageCenteredContainer } from 'src/components/utils/Containers';
import { APPS_CONFIG } from 'src/appConfig';
import bg from 'src/assets/bg.svg';
import { useDataLoader } from 'src/hooks/loadData';
import { getAllowedRoutes, getDefaultRedirectRoute, getRedirectRouteKey } from 'src/routes';

import * as S from './style';

export const IndexPage: React.FC = () => {
  const router = useRouter();
  const { state } = useContext(globalContext);

  const loadingProgress = useDataLoader();

  const appTitle = APPS_CONFIG[state.app].title;

  useEffect(() => {
    if (loadingProgress < 1) return;

    const { redirect } = router.query;
    let shop = state.shop;

    if (shop && shop.onboardingCompleted) {
      if (state.app === 'lebesgue' && state.plan === null) {
        router.push('/billing');
      } else {
        AnalyticsService.setUserId(shop.name);
        AnalyticsService.track('User login', { name: shop.name, email: shop.contactEmail });

        if (
          redirect !== undefined &&
          getAllowedRoutes(state).includes(getRedirectRouteKey(redirect as string))
        ) {
          router.push(redirect as string);
        } else {
          const redirectRoute = getDefaultRedirectRoute(state);
          AnalyticsService.track(`Route changed to ${redirectRoute}`);
          router.push(redirectRoute);
        }
      }
    } else {
      router.push('/onboarding');
    }
  }, [loadingProgress]);

  return (
    <PageWithFullImageBg imgSrc={bg}>
      <Head>Lebesgue{state.shop && ` - ${state.shop.shopName}`}</Head>
      <FullPageCenteredContainer>
        <S.LebesgueLogo src={'/logo.png'} />
        <S.Title>{appTitle}</S.Title>
        <S.SliderContainer>
          <S.SliderValue width={Math.min(100, loadingProgress * 100)} />
        </S.SliderContainer>
        <DescriptionText style={{ marginTop: 16 }}>Loading...</DescriptionText>
      </FullPageCenteredContainer>
    </PageWithFullImageBg>
  );
};

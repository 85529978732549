import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SliderContainer = styled.div`
  position: relative;
  height: 6px;
  display: flex;
  width: 256px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.grayscale.inputBg};
`;

export const SliderValue = styled.div<{ width: number }>`
  position: absolute;
  border-radius: 24px;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.primary.accentBlue};
  width: ${(props) => `${props.width}%`};
  height: 6px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: white;
  width: 200px;
  text-align: center;
  padding-bottom: 64px;
`;

export const LebesgueLogo = styled(motion.img)`
  height: 64px;
  width: 64px;
  margin-bottom: 30px;
`;

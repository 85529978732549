import type { ReactElement } from 'react';
import { SplashLayout } from 'src/components/Layout/SplashLayout';

import { IndexPage } from 'src/modules/Init/IndexPage';
import { NextPageWithLayout } from './_app';

const Home: NextPageWithLayout = () => {
  return <IndexPage />;
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <SplashLayout>{page}</SplashLayout>;
};

export default Home;

import React from 'react';
import Image from 'next/image';

import { FullPageContainer } from 'src/components/utils/Containers';

import * as S from './style';

interface Props {
  imgSrc: StaticImageData;
}

export const PageWithFullImageBg: React.FC<Props> = ({ children, imgSrc }) => {
  return (
    <FullPageContainer>
      <Image src={imgSrc} alt="bg" layout="fill" objectFit="cover" />
      <S.ContentContainer>{children}</S.ContentContainer>
    </FullPageContainer>
  );
};
